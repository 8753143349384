<template>
    <div>
        <v-layout>
            <div class="boxapp">
                <!-- boxapp head -->
                <div class="boxapp-head">
                    <a href="#" @click="$router.go(-1)">
                        <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
                    </a>
                    <h1>Projeto de Voluntariado</h1>
                </div>

                <!-- boxapp body -->
                <div class="box-main">
                    <StepsVolunteering :page-options="pageOptions" :volunteering="project" />

                    <div class="section list-view">
                        <!-- section head -->
                        <div class="head">
                            <h5>PASSO 2</h5>
                            <h4>Abrangência</h4>
                        </div>

                        <!-- google - gmap -->
                        <v-layout w-100>
                            <gmap-autocomplete
                                @place_changed="setPlace"
                                placeholder="Começe a digitar o nome de uma cidade ou país"
                                :options="autocompleteOptions"
                                :selectFirstOnEnter="true"
                                ref="gmap"
                                class="form-input input-autocomplete"
                                required
                            ></gmap-autocomplete>
                            <v-btn
                                flat
                                round
                                color="white"
                                class="btn-primary flex-inherit px-5"
                                large
                                id="coverage-address"
                                v-on:click="addCoverage()"
                                :disabled="this.coverageModel.name == ''"
                            >Adicionar</v-btn>
                            <v-flex justify-space-between d-flex>
                                <label
                                    v-if="showCoverageValidationMessage"
                                    class="d-flex justify-end msg-validation"
                                    :key="showCoverageValidationMessage"
                                >Adicione ao menos uma abrangência</label>
                            </v-flex>
                        </v-layout>

                        <!-- list view -->
                        <div
                            class="wrapper"
                            v-for="cover in project.volunteeringCoverages"
                            :key="cover.Order"
                        >
                            <!-- list item -->
                            <div class="list-item">
                                <div>{{ cover.name }}</div>
                                <div class="action-bar">
                                    <a v-on:click="openConfirmRemoveCoverageModal(cover)">
                                        <i class="fal fa-trash"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BorderSeparation />
                    <div class="cronograma" v-if="this.project.id !== 0">
                        <h4 class="my-4">Depoimentos</h4>
                        <TestimonyVolunteering
                            :volunteeringId="project.id"
                            :value="project.volunteeringTestimonies"
                        />
                    </div>
                    <div v-if="testimonyList.length > 0 && this.project.id !== 0">
                        <div class="box-step-modal flex"
                            :class="{ active: selectedTestimonyId === testimony.id }"
                            @click="selectTestimony(testimony.id)"
                            v-for="testimony in testimonyList"
                            :key="testimony.id"
                        >
                            <div class="mr-3">
                                <TestimonyInstitutionImage :testimony="testimony"
                                                            :alt="''" />
                            </div>
                            <div class="flex-center">
                                <div class="name-testimony">{{ testimony.name }}</div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="box-help-spacer"></div>
            <HelpBox :text="text" />
        </v-layout>
        <div class="footer-step" style="display: flex;">
                <v-btn
                flat
                round
                color="white"
                class="btn-default flex-inherit px-5"
                large
                v-model="pageOptions"
                @click="previousPage"
            >Voltar</v-btn>
            <div class="btn-right">
                <v-btn
                    flat
                    round
                    color="white"
                    class="btn-primary flex-inherit px-5"
                    large
                    v-model="pageOptions"
                    @click="nextPage"
                >Próximo</v-btn>
            </div>
        </div>
        <AucAlert
            ref="coverageAlertComponent"
            title="Você tem certeza que deseja excluir essa abrangência?"
            confirmButtonText="Sim"
            cancelButtonText="Não"
            @confirm="removeCoverage(selectedCoverage)"
        />
        <AucAlert
            ref="donationProductAlertComponent"
            title="Você tem certeza que deseja desativar esse produto de doação?"
            confirmButtonText="Sim"
            cancelButtonText="Não"
            @confirm="disableDonationProduct(selectedDonationProduct)"
        />
    </div>
</template>

<script>
import FooterStep from "@/components/FooterStep.vue";
import HelpBox from "@/components/HelpBox.vue";
import ValueRange from "@/components/ValueRange.vue";
import FileImage from "@/components/FileImage.vue";
import CoverageVolunteering from "@/scripts/models/coverageVolunteering.model.js";
import Project from "@/scripts/models/project.model";
import ProjectService from "@/scripts/services/project.service";
import VolunteeringService from "@/scripts/services/volunteering.service";
import VolunteeringAttributeOfferService from "@/scripts/services/volunteeringAttributeOffer.service";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import TestimonyVolunteering from "@/components/TestimonyVolunteering.vue";
import AucAlert from "@/components/AucAlert.vue";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum.js";
import FileService from "@/scripts/services/file.service";
import VolunteeringItemVue from "../../../components/VolunteeringItem.vue";
import BorderSeparation from "../../../components/BorderSeparation.vue";
import { GetAllRequiredFields } from "@/scripts/helpers/fields-checker.js"
import CommonHelper from "@/scripts/helpers/common.helper";

export default {
    extends: ValidatableComponent,
    components: {
        HelpBox,
        FooterStep,
        ValueRange,
        FileImage,
        AucAlert,
        TestimonyVolunteering
    },
    props: ["pageOptions"],
    data() {
        return {
            text: HelpBoxText.ProjectStep2,
            project: new Project(),
            projectService: new ProjectService(),
            volunteeringService: new VolunteeringService(),
            volunteeringAttributeOfferService: new VolunteeringAttributeOfferService(),
            coverageModel: new CoverageVolunteering(),
            autocompleteOptions: {
                componentRestrictions: { country: "br" },
            },
            projectValidationStatusIndex: 2,
            showCoverageValidationMessage: false,
            selectedCoverage: null,
            textarea: "",
            showFieldProduct: false,
            dialog: false,
            routes: Routes,
            fileService: new FileService(),
            breadcrumbLevels: [
                { text: "Início" },
                { text: "Projeto de Voluntariado" },
                { text: "Passo 2" },
            ],
            requiredFields: [],
            isFormOk: false,
            testimonyList: []
        };
    },
    async mounted() {
        this.requiredFields = await GetAllRequiredFields()
        console.log(this.requiredFields)
    },
    async created() {
        if (!this.$route.query.volunteeringId) {
            CommonHelper.swal("Projeto não informado", "O projeto não foi informado");
            this.$router.go(-1);
        }
        await this.loadProject(this.$route.query.volunteeringId);
        this.$emit("changeLevels", this.breadcrumbLevels);
        this.pageOptions.currentPage = 2;
        this.validationMethod = this.validate;
        this.onValidationSuccessMethod = this.setValidStep;
        this.onValidationFailMethod = this.setInvalidStep;
    },
    methods: {
        previousPage() {
            this.$router.push({
                name: this.routes.app.VolunteeringStep1,
                query: {
                    volunteeringId: this.project.id,
                },
            });
        },
        nextPage() {
            this.volunteeringService.save(this.project).then((data) => {
                this.$router.push({
                    name: this.routes.app.VolunteeringStep3,
                    query: {
                        volunteeringId: data.id,
                    },
                });
            });
        },
        openVolunteeringTestimony() {

        },
        // Confirmation Modals
        openConfirmRemoveCoverageModal(item) {
            this.selectedCoverage = item;
            this.$refs.coverageAlertComponent.open();
        },
        // Coverage
        setPlace: function (locationData) {
            this.coverageModel.name = locationData.formatted_address;
            this.coverageModel.lat = locationData.geometry.location.lat();
            this.coverageModel.long = locationData.geometry.location.lng();
            this.coverageModel.volunteeringId = this.$route.query.volunteeringId;
        },
        addCoverage() {
            console.log(this.coverageModel)
            this.projectService.saveCoverageVolunteering(
                this.refreshCoverageList,
                this.coverageModel
            );
            this.coverageModel = new CoverageVolunteering();
            this.$refs.gmap.$el.value = null;
            this.checkForm()
        },
        refreshCoverageList() {
            this.listCoverageByProjectId();
        },
        async listCoverageByProjectId() {
            return await this.loadProject(this.$route.query.volunteeringId);
        },
        removeCoverage(item) {
            
            if (item.id != 0)
                this.volunteeringService.deleteCoverage(item.id).then((data) => {
                    this.project.volunteeringCoverages =
                        this.project.volunteeringCoverages.filter((x) => x.id != item.id);
                        this.checkForm()
                });
            else {
                this.project.volunteeringCoverages =
                    this.project.volunteeringCoverages.filter((x) => x.id != item.id);
                    this.checkForm()
            }
            

            
        },
        // Validation
        setValidStep() {
            this.$set(this.project.stepsValidationStatus, "step2", true);
        },
        showFailMessage() {
            if (!this.project.coverageList || this.project.coverageList.length == 0) {
                this.$store.commit(
                    "SET_SNACKBAR_MESSAGE",
                    "Você não preencheu todos os campos"
                );
            }
        },
        async loadProject(id) {
            await this.volunteeringService.findById(id).then(async (data) => {
                this.project = data;
                
                await this.volunteeringAttributeOfferService
                    .findByVolunteeringId(id)
                    .then(async (volunteeringAttributeOffers) => {
                        this.project.volunteeringAttributeOffers = volunteeringAttributeOffers;
                        await this.checkForm()
                    });
            });
        },
        validateStep() {
            let valid = this.isValid(false);
            if (valid) this.setValidStep();
            else this.setInvalidStep();

            this.projectService.save(this.project);
        },
        validate(performMethods) {
            let valid = true;
            this.showCoverageValidationMessage = false;
            if (!this.project.coverageList || this.project.coverageList.length == 0) {
                valid = false;
                if (performMethods) this.showCoverageValidationMessage = true;
            }
            if (!valid && performMethods) {
                this.showFailMessage();
            }
            return valid;
        },
        checkForm() {
            let requiredFieldsCounter = 0
            if (this.project.volunteeringCoverages.length > 0) requiredFieldsCounter++
            if (requiredFieldsCounter === this.requiredFields.length) this.isFormOk = true
            else this.isFormOk = false
        },
        listByVolunteeringId() {
            if (this.volunteeringId !== 0) {
                this.volunteeringService.listTestimoniesByVolunteeringId(
                this.volunteeringId
            ).then((data) => {
                this.listByVolunteeringIdCallback(data);
            });
            }
        },
        listByVolunteeringIdCallback(data) {
            this.testimonyList = data;
        },
    },
};
</script>